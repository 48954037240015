.current {
  color: rgb(252, 164, 1);
  }

  h1, h2, h3, h4, h5, h6 { 
    font-family: poppins;
    color: rgb(252, 164, 1);
    margin-top: 3rem; 
  }

  body {
    min-height: 100%;
    color: lightgray;
    font-family: monospace;
    font-size: 1.3rem;
    line-height: 1.3;
    background-color: rgb(24, 24, 24);
  }

  p {
    color: lightgray;
  }

  a {
    color: rgb(105, 163, 255);
  }

  code {
    color: rgb(19, 230, 54);
    background: black;
    border: 2px solid black;
    border-radius: 5px;
  }

  table, th, td {
    border: 1px solid;
    border-collapse: collapse;
  }

  th {
    font-weight: bold;
  }

  div.highlighter-rouge code {
    display: block;
    overflow-x: auto;
    padding: 1rem;
  }  

  footer {
    height:10%;
    width: 100%;
    height: 50px;
    padding-top: 10px;
    text-align: left;   
    }

  * {
      box-sizing: border-box;
    }

  .row::after {
    content: "";
    clear: both;
    display: table;
  }
  
  [class*="col-"] {
    float: left;
    padding: 15px;
  }
  
/* For desktop: */
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}